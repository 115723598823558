/***************************************************
  Imports
***************************************************/

import React from "react"

import PageTypeE from "../../templates/PageTypeE"
import List from "../../components/List"

/***************************************************
  Component
***************************************************/

export default () => {
  return (
    <PageTypeE
      title="Food Bank Software - Ceres"
      description="You need a solid food bank software ERP system to track product donations, grants, food drives, and much more."
      infoList={[
        {
          title: "Simple. Smart. Innovative.",
          text: `Your food bank is a unique entity: a medium organization that receives and distributes millions of pounds of food per year. You need a solid food bank software ERP system to track product donations, grants, food drives, and much more. Ceres is the best breed of Supply Chain Solution powered by Microsoft Business Central specifically designed to work with food banks and their needs.<br/><br/>If you have tried other systems to work with your business, you probably noticed that they have limitations and can be a lot better. Some of these systems make even inconvenience you and your employees. With Ceres, you can have all your data in one system. This can help make information visible to those who need it and helps eliminate duplicate data entry. It can link to your financial donation / CRM solution and to the custom tools you need for your operation.<br/><br/>The best part of about this system is that as you develop and evolve, Ceres stays with you the whole way to make your life easy and your organization’s systems run smoother.`,
        },
        {
          title: "Features of Ceres:",
          text: (
            <List
              className="concise dark"
              items={[
                {
                  text: `Inventory management: Kitting and backpacks, pallet and lot tracking, consume-by-date rules, track againg and turnover, nutritional assessment tools`,
                },
                {
                  text: `Warehouse management: WMS features, system guided transactions- put-away, replenishment, picks, and cycle counts, goods handling order-by-order, goods handling based on the ship date`,
                },
                {
                  text: `Handheld support for multiple workflows and inventory counts`,
                },
                {
                  text: `Product donations: Donor tiers, grocery rescue, donor order importing`,
                },
                {
                  text: `Food and fund drive tracking`,
                },
                {
                  text: `Purchasing and inventory re-order planning`,
                },
                {
                  text: `Agency and order management: Pre-orders with picks and shopping, basic agency survey support, agency appointment scheduling`,
                },
                {
                  text: `General ledger: Fully integrated, robust data segregation and inventory costing`,
                },
                {
                  text: `Fixed asset management`,
                },
                {
                  text: `Full featured AP, AR, credit, and bank account management`,
                },
              ]}
            />
          ),
        },
      ]}
    />
  )
}
